import {SimpleSlider} from '@ext/ndspatterns/Resources/Public/Assets/Js/Components/Simpleslider/simpleslider.js';

const topSpotsSelector = '.js-top-spots';

const topSpotsModule = function() {
  const initTopSpotsSliders = function(topSpotsSlider) {
    const map = topSpotsSlider.querySelector('.js-top-spots-map');
    const mapAreas = map.querySelectorAll('.js-top-spots-map-area');
    const totalItems = topSpotsSlider.querySelectorAll('.js-simpleslider-item').length;
    const prevElemLoop = topSpotsSlider.querySelector('.js-topspots-loop-prev');
    const nextElemLoop = topSpotsSlider.querySelector('.js-topspots-loop-next');
    const prevElem = topSpotsSlider.querySelector('.js-simpleslider-prev');
    const nextElem = topSpotsSlider.querySelector('.js-simpleslider-next');

    if (!totalItems) {
      return;
    }

    prevElemLoop.addEventListener('click', e => {
      if (sliderInstance.currentSliderIndex === 0) {
        e.preventDefault();
        goToSlideWithFadeAnimation(totalItems - 1)
      }
    });

    nextElemLoop.addEventListener('click', e => {
      if (sliderInstance.currentSliderIndex === totalItems - 1) {
        e.preventDefault();
        goToSlideWithFadeAnimation(0)
      }
    });

    const updateNavButtons = function(currentIndex) {
      prevElem.classList.remove('hidden');
      nextElem.classList.remove('hidden');
      prevElem.removeAttribute('disabled');
      nextElem.removeAttribute('disabled');
      prevElemLoop.removeAttribute('disabled');
      nextElemLoop.removeAttribute('disabled');

      if (currentIndex === 0) {
        prevElem.classList.add('hidden');
        prevElemLoop.classList.remove('hidden');
        prevElemLoop.setAttribute('disabled', 'disabled');
      } else {
        prevElem.classList.remove('hidden');
        prevElemLoop.classList.add('hidden');
      }

      if (currentIndex === (totalItems - 1)) {
        prevElem.classList.remove('hidden');
        prevElemLoop.classList.add('hidden');
        nextElem.classList.add('hidden');
        nextElemLoop.classList.remove('hidden');
        nextElemLoop.setAttribute('disabled', 'disabled');
      } else {
        nextElem.classList.remove('hidden');
        nextElemLoop.classList.add('hidden');
      }
    };

    const removeSelected = function() {
      const currentlySelected = map.querySelector('.js-top-spots-map-area.is-selected');
      if (currentlySelected) {
        currentlySelected.classList.remove('is-selected');
      }
    };

    const updateMapHeight = function() {
      topSpotsSlider.style.setProperty('--map-height', getComputedStyle(map).height);
    };

    window.addEventListener('resize', updateMapHeight);

    updateMapHeight();

    topSpotsSlider.querySelector('.js-simpleslider-next').addEventListener('click', function() {
      if (!this.hasAttribute('disabled')) {
        removeSelected();
      }
    });

    topSpotsSlider.querySelector('.js-simpleslider-prev').addEventListener('click', function() {
      if (!this.hasAttribute('disabled')) {
        removeSelected();
      }
    });

    const sliderConfig = {
      afterSlideCallback: function(currentIndex) {
        updateNavButtons(currentIndex);
        if (map.querySelector('.is-active')) {
          map.querySelector('.is-active').classList.remove('is-active');
        }
        if (map.querySelector('[data-index="' + currentIndex + '"]')) {
          map.querySelector('[data-index="' + currentIndex + '"]').classList.add('is-active');
        }
      },
      initSwipe: false
    };

    const sliderInstance = new SimpleSlider(topSpotsSlider, sliderConfig);
    sliderInstance.init();
    updateNavButtons(0);
    topSpotsSlider.querySelector('.js-simpleslider-item').classList.add('is-active');

    mapAreas.forEach(function(area) {
      area.addEventListener('click', function() {
        const index = this.getAttribute('data-index');
        const currentlySelected = map.querySelector('.js-top-spots-map-area.is-selected');

        if (currentlySelected) {
          currentlySelected.classList.remove('is-selected');
        }

        if (currentlySelected === area) {
          return;
        }

        area.classList.add('is-selected');
        goToSlideWithFadeAnimation(index);
      });
    });

    let slideAnimationTimeout = null;
    let previousSlideItem = 0;

    const goToSlideWithFadeAnimation = function(index) {
      if (slideAnimationTimeout) {
        clearTimeout(slideAnimationTimeout);
      }

      const delta = index - sliderInstance.currentSliderIndex;
      const targetIndex = sliderInstance.currentSliderIndex + delta;
      let targetPositionDelta = 0;
      const targetPosition = -1 * ((targetIndex * sliderInstance.itemElemSize) - targetPositionDelta);

      previousSlideItem = sliderInstance.currentSliderIndex = sliderInstance.currentSliderIndex + delta;
      sliderInstance.trackElem.style.height = getComputedStyle(sliderInstance.itemElements[previousSlideItem]).height;

      sliderInstance.trackElem.classList.add('is-fade-in-animation');

      sliderInstance.trackElem.querySelector('.is-active').classList.remove('is-active');

      sliderInstance.itemElements[sliderInstance.currentSliderIndex].classList.add('is-active');

      slideAnimationTimeout = setTimeout(function() {
        sliderInstance.trackElem.classList.remove('is-fade-in-animation');
        sliderInstance.trackElem.style.transform = 'translateX(' + targetPosition + 'px)';
        sliderInstance.trackElem.style.removeProperty('height');
      }, 500);

      updateNavButtons(sliderInstance.currentSliderIndex);
      map.querySelector('.is-active').classList.remove('is-active');
      map.querySelector('[data-index="' + sliderInstance.currentSliderIndex + '"]').classList.add('is-active');
    };

  };

  const init = function() {
    const topSpotsSliders = document.querySelectorAll(topSpotsSelector);
    topSpotsSliders.forEach(function(topSpotsSlider) {
      initTopSpotsSliders(topSpotsSlider);
    });
  };

  return {
    init: init
  };
};

if (document.querySelector(topSpotsSelector) !== null) {
  const topSpotsModuleInstance = new topSpotsModule();
  topSpotsModuleInstance.init();
}

